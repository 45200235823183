import {
  ActionIcon,
  CopyButton,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { createFileRoute } from '@tanstack/react-router'
import { addUtm } from 'use-utm'

import { IconCheck, IconCopy } from '@repo/common/components/Icons'

import { ContentBox } from '@/components/ContentBox'

export const Route = createFileRoute('/utm-maker')({ component: UtmMakerPage })

const base_url = 'https://fission.xyz'
function UtmMakerPage() {
  const form = useForm({
    initialValues: {
      source: '',
      campaign: '',
    },
  })

  const url = addUtm(base_url, form.getValues())

  return (
    <Stack gap="lg" mx="auto" maw="800px" mt="lg">
      <TextInput
        label="source"
        withAsterisk
        size="lg"
        description="identifies which site sent the traffic, for example, traffic from Google or Facebook."
        {...form.getInputProps('source')}
      />
      <TextInput
        label="campaign"
        size="lg"
        description="Helps identify specific promotions or campaigns, for example, when you run an ad campaign.	"
        {...form.getInputProps('campaign')}
      />

      <ContentBox
        justify="center"
        align="center"
        gap="xs"
        direction="row"
        w="fit-content"
        mx="auto"
        variant="bright"
        px="xl"
        py="sm"
        mt="lg"
      >
        <Text size="lg">{url}</Text>
        <CopyButton value={url} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip
              label={copied ? 'Copied' : 'Copy'}
              withArrow
              position="right"
            >
              <ActionIcon
                color={copied ? 'teal' : 'gray'}
                variant="subtle"
                size="lg"
                onClick={copy}
                aria-label="Copy URL"
              >
                {copied ? (
                  <IconCheck width="24px" />
                ) : (
                  <IconCopy width="24px" />
                )}
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>
      </ContentBox>
    </Stack>
  )
}
