import { memo } from 'react'

import { Button, type ButtonProps, Stack, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import { DexCharts } from '@repo/common/components/DexCharts'
import { FissionModal } from '@repo/common/components/FissionModal'
import { is_valid_symbol, Swap } from '@repo/common/components/Swap'
import type { TokenSymbol } from '@repo/common/queries/products'

export const ProcessTrade = memo<{ symbol: TokenSymbol } & ButtonProps>(
  function ProcessTrade({ symbol, ...props }) {
    const [opened, { open, close }] = useDisclosure(false)

    const token_symbol = is_valid_symbol(symbol)

    if (token_symbol == null) {
      return (
        <Text size="sm" miw="78" opacity={0.7}>
          Coming soon
        </Text>
      )
    }

    return (
      <>
        <Button {...props} onClick={open} />
        <FissionModal
          withCloseButton={true}
          opened={opened}
          onClose={close}
          title={`Buy ${token_symbol}`}
          closeOnEscape={true}
          closeOnClickOutside={true}
        >
          <Stack gap="50px">
            <Swap
              token_symbol={token_symbol}
              in_side="USDC"
              onCompleted={close}
            />
            <DexCharts symbol={symbol} size="sm" starting_type="price" />
          </Stack>
        </FissionModal>
      </>
    )
  },
)
