import { useMemo } from 'react'

import { skipToken, useQuery } from '@tanstack/react-query'
import { parseUnits } from 'viem'
import { usePublicClient } from 'wagmi'

import { active_chain } from '@repo/common/blockchain/config'

import type { TokenImageSlug } from '../../components/TokenImage'
import { fissionVaultAbi } from '../blockchain/abis/fissionVaultAbi'
import { positionManagerAbi } from '../blockchain/abis/positionManagerAbi'
import { useGetDecimals } from '../blockchain/useGetDecimals'
import { type InSideType, useGetTokenPrice } from '../fission_dex'
import { calc_tokens } from '../helpers/block_helpers'
import { useTokenInfo } from '../products'

import { keys, useVaultInfo } from './vaults_queries'

export type VaultCompositionType = {
  name: 'TECH' | 'USDC' | 'M^0' | 'USDC ⇄ TECH'
  long_name: string
  usdc_value: number
  percentage: number
  shares: number
} & (
  | {
      type: 'token'
      image_slug: TokenImageSlug
    }
  | {
      type: 'lp'
      image_slug: [TokenImageSlug, TokenImageSlug]
    }
)

// eslint-disable-next-line complexity
export function useGetVaultBalances({ id }: { id: RubyID | undefined }) {
  const vault_address = useVaultInfo({ vault_id: id }).data?.minter_address
  const client = usePublicClient({ chainId: active_chain.id })

  const enabled = id != null && client != null && vault_address != null

  return useQuery({
    queryKey:
      id == null ? [] : keys.vaults.details({ id })._ctx.balances.queryKey,
    queryFn: !enabled
      ? skipToken
      : async () => {
          const result = await client.readContract({
            abi: fissionVaultAbi,
            functionName: 'vault_balances',
            address: vault_address,
          })

          return {
            usdc_balance: result[0],
            fund_balance: result[1],
            usdc_positions_amount: result[2],
            fund_positions_amount: result[3],
            has_position: result[4],
          }
        },
  })
}

// eslint-disable-next-line max-lines-per-function, complexity
export function useGetVaultComposition({ id }: { id: RubyID | undefined }) {
  const { data: vault_data } = useVaultInfo({ vault_id: id })
  const { data: tech_price } = useGetTokenPrice({ symbol: 'TECH' })

  const tech_address = useTokenInfo({ symbol: 'TECH' }).data?.token_address
  const usdc_address = useTokenInfo({ symbol: 'TECH' }).data?.token_address
  const { data: tech_decimals } = useGetDecimals(tech_address)
  const { data: usdc_decimals } = useGetDecimals(usdc_address)

  const vault_address = vault_data?.minter_address

  const client = usePublicClient({ chainId: active_chain.id })

  const enabled =
    id != null &&
    client != null &&
    vault_address != null &&
    tech_decimals != null &&
    usdc_decimals != null &&
    tech_price != null

  return useQuery<{ tvl: number; composition: VaultCompositionType[] }>({
    queryKey:
      id == null ? [] : keys.vaults.details({ id })._ctx.composition.queryKey,
    queryFn: !enabled
      ? skipToken
      : async () => {
          const balances = await client.readContract({
            abi: fissionVaultAbi,
            functionName: 'vault_balances',
            address: vault_address,
          })

          const usdc_shares = calc_tokens(balances[0], usdc_decimals)
          const tech_shares = calc_tokens(balances[1], tech_decimals)
          const usdc_position_shares = calc_tokens(balances[2], usdc_decimals)
          const tech_position_shares = calc_tokens(balances[3], tech_decimals)

          const composition: Omit<VaultCompositionType, 'percentage'>[] = [
            {
              type: 'token',
              image_slug: 'technology',
              name: 'TECH',
              long_name: 'Fission Technology Holdings',
              shares: tech_shares,
              usdc_value: tech_price * tech_shares,
            },
            {
              type: 'token',
              image_slug: 'usdc',
              name: 'USDC',
              long_name: 'USD Coin',
              shares: usdc_shares,
              usdc_value: usdc_shares,
            },
            {
              type: 'token',
              image_slug: 'm-0',
              name: 'M^0',
              long_name: 'M (Yield earning Stable coin)',
              shares: 0,
              usdc_value: 0,
            },
            {
              type: 'lp',
              image_slug: ['technology', 'usdc'],
              name: 'USDC ⇄ TECH',
              long_name: 'USDC ⇄ TECH LP Token',
              shares: 1,
              usdc_value:
                tech_position_shares * tech_price + usdc_position_shares,
            },
          ]

          const tvl = composition.reduce(
            (acc, item) => acc + item.usdc_value,
            0,
          )

          return {
            tvl,
            composition: composition.map(
              (item) =>
                ({
                  ...item,
                  percentage:
                    (tvl > 0 ? item.usdc_value / tvl : 1 / composition.length) *
                    100,
                }) as VaultCompositionType,
            ),
          }
        },
  })
}

export function useGetVaultSellReturnValues({
  id,
  sell_usdc,
}: {
  id: RubyID | undefined
  sell_usdc: number
}) {
  const { data: balances } = useGetVaultBalances({ id })
  const { data: fund_price } = useGetTokenPrice({ symbol: 'TECH' })
  const fund_address = useTokenInfo({ symbol: 'TECH' }).data?.token_address
  const usdc_address = useTokenInfo({ symbol: 'USDC' }).data?.token_address
  const { data: fund_decimals } = useGetDecimals(fund_address)
  const { data: usdc_decimals } = useGetDecimals(usdc_address)

  const is_ready =
    balances != null &&
    fund_price != null &&
    fund_decimals != null &&
    usdc_decimals != null

  return useMemo(() => {
    if (!is_ready) {
      return {
        usdc_tokens: 0,
        fund_tokens: 0,
      }
    }
    const usdc_total_amount =
      balances.usdc_balance + balances.usdc_positions_amount
    const fund_total_amount =
      balances.fund_balance + balances.fund_positions_amount

    const vault_usdc_tokens = calc_tokens(usdc_total_amount, usdc_decimals)
    const vault_fund_tokens = calc_tokens(fund_total_amount, fund_decimals)

    const usdc_total = vault_usdc_tokens + vault_fund_tokens * fund_price

    const percentage_of_total = sell_usdc / usdc_total

    const usdc_tokens = vault_usdc_tokens * percentage_of_total
    const fund_usdc = vault_fund_tokens * fund_price * percentage_of_total
    const fund_tokens = fund_usdc / fund_price

    return {
      usdc_tokens,
      fund_tokens,
    }
  }, [
    balances?.fund_balance,
    balances?.fund_positions_amount,
    balances?.usdc_balance,
    balances?.usdc_positions_amount,
    fund_decimals,
    fund_price,
    is_ready,
    sell_usdc,
    usdc_decimals,
  ])
}

export function useGetVaultShares({ id }: { id: RubyID | undefined }) {
  const tvlt_address = useVaultInfo({ vault_id: id })?.data?.token_address
  const { data: tvlt_decimals } = useGetDecimals(tvlt_address)

  const client = usePublicClient({ chainId: active_chain.id })

  const enabled =
    id != null &&
    client != null &&
    tvlt_address != null &&
    tvlt_decimals != null

  return useQuery<number>({
    queryKey:
      id == null ? [] : keys.vaults.details({ id })._ctx.shares.queryKey,
    queryFn: !enabled
      ? skipToken
      : async () => {
          const shares = await client.readContract({
            abi: positionManagerAbi,
            functionName: 'totalSupply',
            address: tvlt_address,
          })
          return calc_tokens(shares, tvlt_decimals)
        },
  })
}

// Maybe use instead of subgraph?
// export function useGetVaultPrice({ id }: { id: RubyID | undefined }) {
//   const share_data = useGetVaultShares({ id })
//   const compose_data = useGetVaultComposition({ id })
//   const product_data = useProductInfo({ id })

//   if (share_data.isSuccess && share_data.data == 0) {
//     // starting price condition
//     return {
//       ...product_data,
//       data: product_data.data?.price_per_share ?? 0,
//     }
//   }

//   const price = (compose_data.data?.tvl ?? 0) / (share_data.data ?? 1)

//   return {
//     ...merge_queries([share_data, compose_data]),
//     data: price,
//   }
// }

// eslint-disable-next-line complexity, max-lines-per-function
export function useGetDepositValues({
  id,
  deposit_usdc_tokens = 0,
  deposit_fund_tokens = 0,
  in_side,
}: {
  id: RubyID | undefined
  deposit_usdc_tokens: undefined | number
  deposit_fund_tokens: undefined | number
  in_side: InSideType
}) {
  const { data: balances } = useGetVaultBalances({ id })
  const { data: fund_price } = useGetTokenPrice({ symbol: 'TECH' })
  const fund_address = useTokenInfo({ symbol: 'TECH' }).data?.token_address
  const usdc_address = useTokenInfo({ symbol: 'TECH' }).data?.token_address
  const { data: fund_decimals } = useGetDecimals(fund_address)
  const { data: usdc_decimals } = useGetDecimals(usdc_address)

  const enabled =
    balances != null &&
    fund_decimals != null &&
    usdc_decimals != null &&
    fund_price != null

  // eslint-disable-next-line complexity
  const results = useMemo(() => {
    if (
      !enabled ||
      (in_side == 'USDC' && deposit_usdc_tokens == 0) ||
      (in_side == 'TOKEN' && deposit_fund_tokens == 0)
    ) {
      return {
        deposit_usdc_tokens: 0,
        deposit_fund_tokens: 0,
      }
    } else if (in_side == 'USDC') {
      if (balances.has_position == false) {
        return {
          deposit_usdc_tokens,
          deposit_fund_tokens: deposit_usdc_tokens / fund_price,
        }
      }
      if (balances.usdc_positions_amount == 0n) {
        return {
          deposit_usdc_tokens: 0,
          deposit_fund_tokens,
        }
      }

      const suggested_usdc_amount = parseUnits(
        deposit_usdc_tokens.toString(),
        usdc_decimals,
      )
      let matching_fund_amount =
        ((suggested_usdc_amount + balances.usdc_balance) *
          balances.fund_positions_amount) /
        balances.usdc_positions_amount

      if (matching_fund_amount > balances.fund_balance) {
        matching_fund_amount -= balances.fund_balance
      } else {
        matching_fund_amount = 0n
      }
      return {
        deposit_usdc_tokens,
        deposit_fund_tokens: calc_tokens(matching_fund_amount, fund_decimals),
      }
    }
    // TOKEN
    if (balances.has_position == false) {
      return {
        deposit_usdc_tokens: deposit_fund_tokens * fund_price,
        deposit_fund_tokens,
      }
    }
    if (balances.fund_positions_amount == 0n) {
      return {
        deposit_usdc_tokens,
        deposit_fund_tokens: 0,
      }
    }

    const suggested_fund_amount = parseUnits(
      deposit_fund_tokens.toString(),
      fund_decimals,
    )
    let matching_usdc_amount =
      ((suggested_fund_amount + balances.fund_balance) *
        balances.usdc_positions_amount) /
      balances.fund_positions_amount
    if (matching_usdc_amount > balances.usdc_balance) {
      matching_usdc_amount -= balances.usdc_balance
    } else {
      matching_usdc_amount = 0n
    }
    return {
      deposit_usdc_tokens: calc_tokens(matching_usdc_amount, usdc_decimals),
      deposit_fund_tokens,
    }
  }, [
    balances?.fund_balance,
    balances?.fund_positions_amount,
    balances?.has_position,
    balances?.usdc_balance,
    balances?.usdc_positions_amount,
    deposit_fund_tokens,
    deposit_usdc_tokens,
    enabled,
    fund_decimals,
    fund_price,
    in_side,
    usdc_decimals,
  ])

  return {
    enabled,
    ...results,
  }
}
