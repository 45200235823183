import { useCallback } from 'react'

import { Flex, Group, Stack, Text, Title } from '@mantine/core'
import { createFileRoute, useNavigate } from '@tanstack/react-router'

import { DexCharts } from '@repo/common/components/DexCharts'
import { LinkButton } from '@repo/common/components/LinkButton'
import { PoolStats } from '@repo/common/components/PoolStats'
import { Swap } from '@repo/common/components/Swap'
import { TokenRender } from '@repo/common/components/TokenRender'

// do to TanStack Router auto generate file routes
// prettier-ignore
export const Route = createFileRoute('/_app/trade/')({
  component: TradePage,
})

function TradePage() {
  const symbol = 'TECH'

  const navigate = useNavigate()
  const onCompleted = useCallback(() => {
    void navigate({ to: '/portfolio' })
  }, [navigate])

  return (
    <>
      <Stack gap="sm">
        <Group align="center">
          <TokenRender
            type="lp"
            image_slug={['usdc', 'technology']}
            size="64px"
          />
          <Flex
            direction={{ base: 'column', sm: 'row' }}
            align="baseline"
            gap="sm"
          >
            <Stack gap="0">
              <Title fz="xl" fw="bold" lh="1" opacity={0.8}>
                Trade a Token
              </Title>
              <Text pl="0.15em" size="sm" opacity={0.6}>
                USDC / TECH - 1.0%
              </Text>
            </Stack>
          </Flex>
        </Group>
        <Group
          w="100%"
          mt="lg"
          gap="calc(2*var(--mantine-spacing-xxl))"
          align="flex-start"
        >
          <Stack w="min-content">
            <Swap
              token_symbol={symbol}
              in_side="USDC"
              onCompleted={onCompleted}
            />
            <Group justify="flex-end" w="100%" mt="40px" opacity={0.6}>
              <LinkButton
                to="/trade/$symbol/create-position"
                size="sm"
                variant="subtle"
                params={{ symbol }}
              >
                Create Manual Position
              </LinkButton>
            </Group>
          </Stack>
          <Stack flex="1 1 100vw" maw="44.4rem" gap="md">
            <PoolStats symbol={symbol} />
            <DexCharts symbol={symbol} size="lg" starting_type="price" />
          </Stack>
          <Stack flex="1 1" maw="300px"></Stack>
        </Group>
      </Stack>
    </>
  )
}
