import { memo, useMemo } from 'react'

import { Box, Center, Group, Loader, Paper, Text } from '@mantine/core'
import isChromatic from 'chromatic'
import dayjs from 'dayjs'
import * as _ from 'lodash-es'
import {
  Bar,
  BarChart,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import {
  date_formatter,
  full_date_formatter,
  percentage_formatter,
  tick_wrapper,
  usdFormatter,
} from '@repo/common/helpers/formatters'
import { useGetPriceHistory } from '@repo/common/queries/fission_dex'
import { useFundDailyNav, useFundInfo } from '@repo/common/queries/funds'

import { x_tick_formatting, y_tick_formatting } from '@/helpers/chart_colors'

export const Price_Colors = {
  stroke: 'var(--mantine-color-chart_a-0)',
  fill: 'var(--mantine-color-chart_a-0)',
}
export const Nav_Colors = {
  stroke: 'var(--mantine-color-chart_a-4)',
  fill: 'var(--mantine-color-chart_a-4)',
}
export const Round_Colors = {
  stroke: 'var(--mantine-color-chart_b-2)',
  fill: 'var(--mantine-color-chart_b-2)',
}
export const Discount_Colors = {
  stroke: 'var(--mantine-color-blue-4)',
  fill: 'var(--mantine-color-blue-4)',
}

function legend_name(name: string) {
  switch (name) {
    case 'price_per_share':
      return 'Price Per Share: Current'
    case 'nav_price_per_share':
      return 'Nav Per Share: Current'
    case 'round_price_per_share':
      return 'Nav Per Share: Last Round'
    default:
      return 'Discount from Last Round'
  }
}

const chart_height = 500
const chart_x_line = 80
const chart_y_top = 8

// eslint-disable-next-line max-lines-per-function, complexity
export const PerShareChart = memo<{ fund_id: RubyID }>(function PerShareChart({
  fund_id,
}) {
  const nav_query = useFundDailyNav({ id: fund_id })
  const { data: fund } = useFundInfo({ fund_id })
  const price_history_query = useGetPriceHistory({
    period_type: 'daily',
    symbol: fund?.symbol == 'TECH' ? 'TECH' : undefined,
  })

  const price_history = useMemo(() => {
    const dates = _.uniqBy(
      [
        ..._.map(nav_query.data, 'date'),
        ..._.map(price_history_query.data, 'date'),
      ],
      (d) => dayjs(d).format('YYYY-MM-DD'),
    ).sort()
    _.remove(dates, (d) => dayjs(d).isSame(dayjs('2024-01-01'), 'day'))

    return dates.map((date) => {
      const nav = nav_query.data.find((n) =>
        dayjs(date).isSame(dayjs(n.date), 'day'),
      )
      const history = price_history_query.data.find((h) =>
        dayjs(date).isSame(dayjs(h.date), 'day'),
      )

      return {
        date,
        price_per_share: history?.token_usdc_price,
        nav_price_per_share: nav?.nav_price_per_share,
        round_price_per_share: nav?.round_price_per_share,
      }
    })
  }, [nav_query.data, price_history_query.data])

  const last_round_price_per_share = _.findLast(
    price_history,
    (h) => h.round_price_per_share != null,
  )?.round_price_per_share

  const last_price_per_share = _.findLast(
    price_history,
    (h) => h.price_per_share != null,
  )?.price_per_share

  const last_nav_price_per_share = _.findLast(
    price_history,
    (h) => h.nav_price_per_share != null,
  )?.nav_price_per_share

  const last_history = _.last(price_history)
  if (last_history != null && last_history?.nav_price_per_share == null) {
    last_history.nav_price_per_share = last_nav_price_per_share
  }
  if (last_history != null && last_history.round_price_per_share == null) {
    last_history.round_price_per_share = last_round_price_per_share
  }

  const discount =
    last_round_price_per_share == null || last_price_per_share == null
      ? 0
      : ((last_round_price_per_share - last_price_per_share) /
          last_round_price_per_share) *
        100

  // eslint-disable-next-line complexity
  const [bar_margin, graph_top, graph_bottom] = useMemo(() => {
    const top_value =
      _.max([
        ..._.map(price_history, 'price_per_share'),
        ..._.map(price_history, 'round_price_per_share'),
        ..._.map(price_history, 'nav_price_per_share'),
      ]) ?? 0

    const bottom_value =
      _.min([
        ..._.map(price_history, 'price_per_share'),
        ..._.map(price_history, 'round_price_per_share'),
        ..._.map(price_history, 'nav_price_per_share'),
      ]) ?? 0
    const top_graph_value = Math.ceil(top_value / 5) * 5
    const bottom_graph_value = Math.floor(bottom_value / 5) * 5

    const last_min = Math.min(
      last_price_per_share ?? 0,
      last_round_price_per_share ?? 0,
    )

    const max_chart_size = chart_height - chart_x_line - chart_y_top
    const chart_line_top_offset =
      (max_chart_size * (top_graph_value - top_value)) /
      (top_graph_value - bottom_graph_value)
    const chart_line_bottom_offset =
      (max_chart_size * (bottom_value - bottom_graph_value)) /
      (top_graph_value - bottom_graph_value)

    const chart_line_distance =
      max_chart_size - chart_line_top_offset - chart_line_bottom_offset

    const bar_height =
      (chart_line_distance * (top_value - last_min)) /
      (top_value - bottom_value)

    return [
      {
        top: chart_line_top_offset,
        bottom:
          chart_x_line +
          chart_line_top_offset +
          chart_y_top +
          chart_line_distance -
          bar_height,
      },
      top_graph_value,
      bottom_graph_value,
    ]
  }, [price_history, last_price_per_share, last_round_price_per_share])

  if (nav_query.isLoading || price_history_query.isLoading) {
    return (
      <Center w="100%" h="300">
        <Loader color="primary" type="bars" />
      </Center>
    )
  }

  return (
    <Group w="100%" align="flex-start" wrap="nowrap">
      <Box flex="1 1 auto" h={{ base: chart_height + 150, xs: chart_height }}>
        <ResponsiveContainer>
          <ComposedChart data={price_history}>
            <XAxis
              dataKey="date"
              scale="time"
              type="number"
              domain={['dataMin', 'dataMax']}
              tick={x_tick_formatting}
              tickFormatter={tick_wrapper(date_formatter)}
            />
            <YAxis
              yAxisId="left"
              type="number"
              tick={y_tick_formatting}
              tickFormatter={tick_wrapper(usdFormatter)}
              domain={[graph_bottom, graph_top]}
              allowDataOverflow={false}
            />
            <Tooltip
              content={({ label, payload }) => (
                <ChartTooltip
                  label={label as string}
                  payload={payload as ToolTipType['payload']}
                />
              )}
            />
            <Legend
              formatter={legend_name}
              wrapperStyle={{
                fontSize: 'var(--mantine-font-size-sm)',
                lineHeight: 'var(--mantine-line-height-sm)',
              }}
            />
            <Line
              yAxisId="left"
              isAnimationActive={!isChromatic()}
              type="monotone"
              connectNulls={true}
              dataKey="price_per_share"
              {...Price_Colors}
              strokeWidth={2}
              dot={false}
            />
            <Line
              yAxisId="left"
              isAnimationActive={!isChromatic()}
              type="monotone"
              connectNulls={true}
              dataKey="nav_price_per_share"
              {...Nav_Colors}
              strokeWidth={2}
              dot={false}
            />
            <Line
              yAxisId="left"
              isAnimationActive={!isChromatic()}
              type="monotone"
              connectNulls={true}
              dataKey="round_price_per_share"
              {...Round_Colors}
              strokeWidth={2}
              dot={false}
            />
            <Line
              yAxisId="left"
              isAnimationActive={!isChromatic()}
              dataKey="discount"
              hide={false}
              {...Discount_Colors}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
      <Box w="80" h={chart_height}>
        <ResponsiveContainer>
          <BarChart data={[{ discount }]} margin={bar_margin}>
            <YAxis
              orientation="right"
              type="number"
              tick={y_tick_formatting}
              domain={discount < 0 ? ['dataMin', 0] : [0, 'dataMax']}
              tickFormatter={tick_wrapper(percentage_formatter)}
              ticks={[discount]}
              interval={0}
              reversed
            />
            <XAxis hide={true} />
            <Bar
              isAnimationActive={!isChromatic()}
              dataKey="discount"
              {...Discount_Colors}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Group>
  )
})

export type ToolTipType = {
  label: string | number
  payload?: { name: string; color: string; value: number }[]
}

export function ChartTooltip({ label, payload }: ToolTipType) {
  if (payload == null || payload.length == 0) return null

  const items = [
    ...payload,
    {
      name: 'discount',
      color: Discount_Colors.stroke,
      value:
        ((_.last(payload)!.value - payload[0].value) / _.last(payload)!.value) *
        100,
    },
  ]

  return (
    <Paper px="md" py="sm" withBorder shadow="md" radius="md">
      <Text size="sm" mb={5}>
        {full_date_formatter(label)}
      </Text>
      {items.map((item) => (
        <Text key={item.name} c={item.color} size="md" fw="bold">
          {legend_name(item.name)}:{' '}
          {item.name != 'discount'
            ? usdFormatter(item.value)
            : percentage_formatter(item.value)}
        </Text>
      ))}
    </Paper>
  )
}
