import { createContext, type ReactNode, useContext } from 'react'

import { useSetState } from '@mantine/hooks'

const defaults = {
  is_demo_site: true,
  is_main_site: false,
}

export type FlagsValueType = typeof defaults

const FlagsContexts = createContext<{
  values: FlagsValueType
  setValuesForTesting: (args: Partial<FlagsValueType>) => void
}>({
  values: defaults,
  setValuesForTesting: () => {},
})

const demo_sites = ['demo', 'testnet']

export const is_demo_site = () =>
  demo_sites.includes(window.location.hostname.split('.')[0])

export const is_main_site = () =>
  window.location.hostname == 'fission.xyz' ||
  window.location.hostname == 'www.fission.xyz'

export function FlagsProvider({ children }: { children: ReactNode }) {
  const [values, setValuesForTesting] = useSetState<FlagsValueType>({
    is_demo_site: is_demo_site(),
    is_main_site: is_main_site(),
  })

  return (
    <FlagsContexts.Provider
      value={{
        values,
        setValuesForTesting,
      }}
    >
      {children}
    </FlagsContexts.Provider>
  )
}

export function useGetFlags() {
  return useContext(FlagsContexts).values
}

export function useSetFlagForTesting() {
  return useContext(FlagsContexts).setValuesForTesting
}
