import { memo } from 'react'

import { ActionIcon, Group, Image } from '@mantine/core'
import { usePostHog } from 'posthog-js/react'

import { IconBrandX } from '../Icons'

import linkedin_url from './linkedin.svg'

export const FollowUs = memo(function FollowUs() {
  const posthog = usePostHog()

  return (
    <Group gap="2px">
      <ActionIcon
        variant="subtle"
        color="primary.2"
        component="a"
        size="lg"
        target="_blank"
        aria-label="Follow FissionXYZ on LinkedIn"
        title="Follow FissionXYZ on LinkedIn"
        href="https://www.linkedin.com/company/fissionxyz"
        onClick={() => posthog.capture('LinkedIn following')}
      >
        <Image
          color="primary.2"
          src={linkedin_url}
          style={{ width: '65%', height: '65%' }}
          alt="LinkedIn"
        />
      </ActionIcon>
      <ActionIcon
        variant="subtle"
        color="primary.2"
        component="a"
        size="lg"
        target="_blank"
        aria-label="Follow FissionXYZ on X"
        title="Follow FissionXYZ on X"
        href="https://twitter.com/intent/follow?original_referer=https%3A%2F%2Ffission.xyz&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Efollow%7Ctwgr%5Ex&region=follow_link&screen_name=FissionXYZ"
        onClick={() => posthog.capture('X following')}
      >
        <IconBrandX style={{ width: '70%', height: '70%' }} />
      </ActionIcon>
    </Group>
  )
})
