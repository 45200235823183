import { memo, useCallback } from 'react'

import { ActionIcon, Affix, Menu } from '@mantine/core'
import * as Sentry from '@sentry/react'
import { usePostHog } from 'posthog-js/react'

import { useBeacon } from '../../hooks/useBeacon'
import { IconBug, IconHelp, IconSupport } from '../Icons'

import classes from './GetHelp.module.css'

export const GetHelp = memo(function GetHelp() {
  const beacon = useBeacon()
  const posthog = usePostHog()

  const openSentry = useCallback(() => {
    const feedback = Sentry.getFeedback()
    if (feedback == null) return

    posthog.capture('help requested', { type: 'bug' })
    feedback
      .createForm()
      .then((form) => {
        form.appendToDom()
        form.open()
      })
      .catch(console.error)
  }, [posthog])

  const openHelpScout = useCallback(() => {
    beacon('open')
    posthog.capture('help requested', { type: 'general' })
  }, [beacon, posthog])

  return (
    <Affix position={{ bottom: 40, right: 40 }}>
      <Menu
        shadow="md"
        width={200}
        classNames={classes}
        position="top-end"
        withArrow
        arrowPosition="center"
        trigger="click-hover"
      >
        <Menu.Target>
          <ActionIcon
            variant="filled"
            aria-label="Get Help"
            size="xl"
            radius="md"
          >
            <IconHelp className={classes.action_icon} stroke={1.5} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            leftSection={<IconSupport className={classes.icon} />}
            onClick={openHelpScout}
          >
            Get Help
          </Menu.Item>
          <Menu.Item
            leftSection={<IconBug className={classes.icon} />}
            onClick={openSentry}
          >
            Report a Bug
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Affix>
  )
})
