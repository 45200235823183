import { memo, useMemo } from 'react'

import { Sparkline } from '@mantine/charts'
import isChromatic from 'chromatic'

import {
  useGetVaultHistory,
  type VaultCompositionType,
} from '@repo/common/queries/vaults'

export const VaultShareSparkline = memo<
  Omit<ReactProps<typeof Sparkline>, 'data'> & {
    id: RubyID
    composition_name: VaultCompositionType['name']
  }
>(function VaultShareSparkline({ id, composition_name, ...props }) {
  const { data: history_data } = useGetVaultHistory({
    id,
  })

  const history = useMemo(() => {
    return history_data
      .map((item) => {
        if (composition_name === 'USDC') {
          return Number(item.usdc_value)
        } else if (composition_name === 'TECH') {
          return Number(item.token_usdc_value)
        } else if (composition_name === 'USDC ⇄ TECH') {
          return Number(item.position_usdc_value)
        }
        return null
      })
      .filter((r): r is number => r != null)
  }, [composition_name, history_data])

  if (history.length === 1) {
    history.unshift(0)
  }

  return (
    <Sparkline
      h={40}
      miw="80px"
      maw="100%"
      curveType="linear"
      color="primary.3"
      fillOpacity={0.6}
      strokeWidth={2}
      areaProps={{
        isAnimationActive: !isChromatic(),
      }}
      {...props}
      data={history}
    />
  )
})
