import { useMutation, useQueryClient } from '@tanstack/react-query'
import { usePostHog } from 'posthog-js/react'
import { parseUnits } from 'viem'
import { usePublicClient, useSwitchChain, useWriteContract } from 'wagmi'

import { active_chain } from '@repo/common/blockchain/config'

import { fissionFundMinterAbi } from '../blockchain/abis/fissionFundMinterAbi'
import { useCreateAndSignPermit2 } from '../blockchain/useCreateAndSignPermit2'
import {
  set_looking_for_change,
  useGetWalletBalance,
} from '../blockchain/useGetWalletBalance'
import { keys as wallet_keys } from '../blockchain/useGetWalletBalance'
import { useProductInfo, useTokenInfo } from '../products'

// eslint-disable-next-line complexity
export function useBuyFund({ id }: { id: RubyID }) {
  const queryClient = useQueryClient()
  const { writeContractAsync } = useWriteContract()
  const { switchChainAsync } = useSwitchChain()
  const { data: product } = useProductInfo({ id })
  const { wallet_address, decimals: usdc_decimals } =
    useGetWalletBalance('USDC')
  const client = usePublicClient({ chainId: active_chain.id })
  const posthog = usePostHog()
  const { createSingleAsync, isReady: permit_is_ready } =
    useCreateAndSignPermit2()

  const minter_address = product?.minter_address
  const usdc_address = useTokenInfo({ symbol: 'USDC' }).data?.token_address

  const isReady =
    permit_is_ready &&
    wallet_address != null &&
    product != null &&
    usdc_decimals != null &&
    usdc_address != null &&
    minter_address != null &&
    client != null

  const result = useMutation({
    mutationFn: async ({ usdc_tokens }: { usdc_tokens: number }) => {
      if (!isReady) return

      const amount = parseUnits(usdc_tokens.toString(), usdc_decimals)

      // ensure network is correct
      await switchChainAsync({ chainId: active_chain.id })

      const { permitSingle, signature } = await createSingleAsync({
        token_address: usdc_address,
        token_symbol: 'USDC',
        destination_address: minter_address,
        token_amount: amount,
      })

      const tx = await writeContractAsync({
        abi: fissionFundMinterAbi,
        address: minter_address,
        functionName: 'mint_fund',
        args: [permitSingle, signature],
        chainId: active_chain.id,
      })

      await client.waitForTransactionReceipt({ hash: tx })
      posthog.capture('fund minted', {
        fund: product.symbol,
        usdc_tokens,
      })
    },
    onSuccess: async () => {
      set_looking_for_change(product!.token_address)
      await queryClient.invalidateQueries({
        queryKey: wallet_keys.balance.wallet(wallet_address!).queryKey,
      })
    },
  })
  return {
    ...result,
    isReady,
  }
}
