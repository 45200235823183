import { memo, type ReactNode } from 'react'

import { Button, Group, Text, TextInput } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import isEmail from 'validator/es/lib/isEmail'
import { z } from 'zod'

import {
  useLogin,
  useRequestResetLink,
  useSignup,
} from '@repo/common/queries/auth'

import { form_input_props, LoginSignupForm } from '../LoginSignupForm'

const password_message = {
  message:
    'Password must be at least 16 characters OR at least 8 characters including a number and a letter',
}
export const password_zod = z.union([
  z.string().trim().min(16, password_message).max(80),
  z
    .string()
    .trim()
    .min(8, password_message)
    .max(80)
    .regex(/\d/, password_message),
])

const schema = z.union([
  z.object({
    mode: z.literal('login'),
    email: z.string().refine(isEmail, { message: 'Invalid email address' }),
    password: password_zod,
  }),
  z.object({
    mode: z.literal('signup'),
    email: z.string().refine(isEmail, { message: 'Invalid email address' }),
    company_name: z.string().trim(),
    name: z.string().trim().min(3).max(80),
    password: password_zod,
  }),
])

export const LoginSignup = memo<{
  starting_mode?: 'login' | 'signup'
  onSuccess?: () => void
  initial_values_override?: { email?: string; name?: string; password?: string }
  children?: ReactNode
  // eslint-disable-next-line complexity, max-lines-per-function
}>(function LoginSignup({
  starting_mode = 'login',
  onSuccess,
  initial_values_override,
  children,
}) {
  const form = useForm({
    initialValues: {
      email: '',
      name: '',
      password: '',
      company_name: '',
      ...initial_values_override,
      mode: starting_mode,
    },
    validate: zodResolver(schema),
  })
  const isLogin = form.values.mode === 'login'

  const login = useLogin()
  const signup = useSignup()
  const reset_link = useRequestResetLink()
  const isPending = login.isPending || signup.isPending

  return (
    <LoginSignupForm
      form={form}
      action={isLogin ? login : signup}
      button_text={isLogin ? 'Login' : 'Signup'}
      onSuccess={onSuccess}
      after_button={
        <Group justify="flex-start">
          <Button
            type="button"
            color="primary"
            variant="subtle"
            size="sm"
            onClick={() =>
              form.setFieldValue('mode', isLogin ? 'signup' : 'login')
            }
          >
            {isLogin ? 'Signup' : 'Login'} instead?
          </Button>
        </Group>
      }
    >
      <>
        {children}
        {isLogin || (
          <TextInput
            label="name"
            withAsterisk
            {...form.getInputProps('name')}
            disabled={isPending}
            {...form_input_props}
          />
        )}
        {isLogin || (
          <TextInput
            label="company name"
            placeholder="optional"
            {...form.getInputProps('company_name')}
            disabled={isPending}
            {...form_input_props}
          />
        )}
        {isLogin && login.isError && (
          <Group>
            <Text>Forgot Password?</Text>
            <Button
              loading={reset_link.isPending}
              disabled={reset_link.isSuccess}
              onClick={() => reset_link.mutate(form.getValues())}
            >
              {reset_link.isSuccess ? 'Sent!' : 'Send reset email'}
            </Button>
          </Group>
        )}
        <TextInput
          label="email"
          withAsterisk
          {...form.getInputProps('email')}
          disabled={isPending}
          {...form_input_props}
        />
        <TextInput
          label="password"
          type="password"
          withAsterisk
          {...form.getInputProps('password')}
          disabled={isPending}
          {...form_input_props}
        />
      </>
    </LoginSignupForm>
  )
})
