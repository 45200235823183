import { memo } from 'react'

import { Button, Group, Loader, Text } from '@mantine/core'

import classes from './WalletExecuteButton.module.css'

type ButtonProps = ReactProps<typeof Button<'button'>>

export const WalletExecuteButton = memo<ButtonProps>(
  function WalletExecuteButton(props) {
    return (
      <Button
        loaderProps={{ children: <ExecuteLoader /> }}
        classNames={classes}
        miw="20em"
        {...props}
      />
    )
  },
)

const ExecuteLoader = memo(function ExecuteLoader() {
  return (
    <Group wrap="nowrap" w="100%" h="100%" justify="center" align="center">
      <Loader color="primary.0" size="sm" />
      <Text span inherit>
        Confirm with wallet
      </Text>
    </Group>
  )
})
