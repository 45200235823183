import { memo, useMemo } from 'react'

import { Group, Progress, Stack, Text, Title, Tooltip } from '@mantine/core'
import dayjs from 'dayjs'

import { ContentBox } from '../../../../apps/labs/src/components/ContentBox'
import {
  usdLargeNumberFormatter,
  value_formatter,
} from '../../helpers/formatters'
import { useGetPoolStats, useGetTokenPrice } from '../../queries/fission_dex'
import type { TokenSymbol } from '../../queries/products'
import { Liquidity_Colors } from '../LiquidityChart'
import { Metrics } from '../Metrics'
import { TokenRender } from '../TokenRender'

// eslint-disable-next-line max-lines-per-function
export const PoolStats = memo<{ symbol: TokenSymbol }>(function PoolStats({
  symbol,
}) {
  const { data: stats } = useGetPoolStats({ symbol })
  const { data: tech_price } = useGetTokenPrice({ symbol })

  const data = useMemo(() => {
    if (stats == null || tech_price == null) return []
    const t_in_usdc = stats.fund_tokens * tech_price
    return [
      {
        label: 'TVL',
        since_date: stats.since_date,
        value: usdLargeNumberFormatter(stats.usdc_tokens + t_in_usdc, {
          maximumFractionDigits: 2,
        }),
      },
      {
        label: 'Lifetime volume',
        since_date: stats.since_date,
        value: usdLargeNumberFormatter(
          stats.usdc_volume + stats.fund_volume * tech_price,
          {
            maximumFractionDigits: 2,
          },
        ),
      },
      {
        label: '24H volume',
        since_date: dayjs().subtract(1, 'day'),
        value: usdLargeNumberFormatter(
          stats.last_day_usdc_volume + stats.last_day_fund_volume * tech_price,
          {
            maximumFractionDigits: 2,
          },
        ),
      },
    ]
  }, [stats, tech_price])

  if (stats == null || tech_price == null) return null
  const fund_in_usdc = stats.fund_tokens * tech_price

  return (
    <ContentBox gap="lg" p="md">
      <Stack gap="xs">
        <Title order={2} lh="1" mb="lg">
          Pool Balances
        </Title>
        <Group justify="space-between">
          <Group gap="0.25em">
            <Text size="md">{value_formatter(stats.usdc_tokens)}</Text>
            <TokenRender
              type="token"
              size="var(--mantine-spacing-lg)"
              image_slug="usdc"
            />
            <Text size="md">USDC</Text>
          </Group>
          <Group gap="0.25em">
            <Text size="md">{value_formatter(stats.fund_tokens)}</Text>
            <TokenRender
              type="token"
              size="var(--mantine-spacing-lg)"
              image_slug="technology"
            />
            <Text size="md">{symbol}</Text>
          </Group>
        </Group>
        <Progress.Root size="xl">
          <Tooltip
            label={`USDC ${usdLargeNumberFormatter(stats.usdc_tokens, { maximumFractionDigits: 2 })}`}
          >
            <Progress.Section
              value={
                (100 * stats.usdc_tokens) / (stats.usdc_tokens + fund_in_usdc)
              }
              color={Liquidity_Colors.usdc}
            />
          </Tooltip>
          <Tooltip
            label={`${symbol} ${usdLargeNumberFormatter(fund_in_usdc, { maximumFractionDigits: 2 })}`}
          >
            <Progress.Section
              value={(100 * fund_in_usdc) / (stats.usdc_tokens + fund_in_usdc)}
              color={Liquidity_Colors.token}
            />
          </Tooltip>
        </Progress.Root>
      </Stack>
      <Metrics data={data} />
    </ContentBox>
  )
})
