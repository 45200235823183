import { createFileRoute } from '@tanstack/react-router'

import { LoginSignupPage } from '@/components/LoginSignupPage'

// do to TanStack Router auto generate file routes
// prettier-ignore
export const Route = createFileRoute('/_appish/login/')({
  component: LoginPage,
})

function LoginPage() {
  return <LoginSignupPage starting_mode="login" />
}
