import { skipToken, useQuery } from '@tanstack/react-query'

import { graphql_call } from '@repo/common/queries/helpers/server_query'

import { keys, useIsAuthenticated } from '../auth'

import { query_get_helpscout } from './helpscout_graphql'

export function useGetHelpscoutSignature() {
  const is_auth = useIsAuthenticated()

  const enabled = is_auth

  return useQuery({
    queryKey: keys.user.helpscout_signature.queryKey,
    queryFn: !enabled
      ? skipToken
      : async () => {
          const data = await graphql_call({ query: query_get_helpscout })
          return data?.helpscout_signature
        },
    gcTime: Infinity,
    staleTime: Infinity,
  })
}
