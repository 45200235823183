import type { QueryClient } from '@tanstack/react-query'
import {
  createRootRouteWithContext,
  Navigate,
  Outlet,
  ScrollRestoration,
} from '@tanstack/react-router'

import { GetHelp } from '@repo/common/components/GetHelp'
import { PageTracking } from '@repo/common/core/PageTracking'
import { type AuthDataType, useAuth } from '@repo/common/queries/auth'

export const Route = createRootRouteWithContext<{
  auth: AuthDataType
  queryClient: QueryClient
}>()({
  notFoundComponent: function NotFound() {
    const auth = useAuth()
    return <Navigate to={auth.switch('/portfolio', '/')} />
  },
  component: Root,
})

function Root() {
  return (
    <>
      <GetHelp />
      <ScrollRestoration />
      <PageTracking />
      <Outlet />
    </>
  )
}
