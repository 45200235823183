import { memo, useEffect } from 'react'

import { useLocation } from '@tanstack/react-router'
import { usePostHog } from 'posthog-js/react'

import { useBeacon } from '../hooks/useBeacon'

// global variable as there is only one URL
let previous_href: string | undefined

export const PageTracking = memo(function PageTracking() {
  const href = useLocation({
    select: (location) => location.href,
  })
  const posthog = usePostHog()
  const beacon = useBeacon()

  useEffect(() => {
    if (href !== previous_href) {
      // eslint-disable-next-line @cspell/spellchecker
      posthog.capture('$pageview')
      beacon('event', { type: 'page-viewed', url: href, title: document.title })
      beacon('suggest')
      if (href === '/') {
        beacon('navigate', '/')
      }
    }

    previous_href = href
  }, [beacon, href, posthog])

  return null
})
