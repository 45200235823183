import { memo, useEffect, useState } from 'react'

import { Anchor, Group, Loader, Text } from '@mantine/core'
import { useNavigate } from '@tanstack/react-router'

import { LoginSignup } from '@repo/common/components/LoginSignup'
import { useAuth } from '@repo/common/queries/auth'
import { useUpdateMembership } from '@repo/common/queries/membership'

import { ContentBox } from '../ContentBox'

export const LoginSignupPage = memo<{
  starting_mode: ReactProps<typeof LoginSignup>['starting_mode']
  membership_code?: string
}>(function LoginSignupPage({ starting_mode, membership_code }) {
  const { data } = useAuth()
  const navigate = useNavigate()
  const update = useUpdateMembership()
  const [called, set_called] = useState(false)

  useEffect(() => {
    if (data?.is_authenticated == null) return

    if (data?.is_authenticated) {
      if (membership_code && data?.is_member == false) {
        if (called == false) {
          update.mutate({ code: membership_code })
          set_called(true)
        }
      } else {
        void navigate({ to: '/portfolio', replace: true })
      }
    }
  }, [
    called,
    data?.is_authenticated,
    data?.is_member,
    membership_code,
    navigate,
    update,
  ])

  return (
    <Group
      gap="xl"
      wrap="nowrap"
      pl={{ base: 'sm', sm: '0' }}
      pr={{ base: 'sm', sm: 'xl' }}
      align="top"
      maw="400"
      mx="auto"
    >
      {update.isPending && (
        <ContentBox direction="row" gap="lg" align="center">
          <Text>Updating Membership</Text>
          <Loader color="blue" />
        </ContentBox>
      )}
      {update.isError && (
        <ContentBox gap="lg" align="center">
          <Text>Error: {update.error.message.split(':')[0]}</Text>
          <Text ta="center">
            If you have any questions or issues please reach out to us at{' '}
            <Anchor href="mailto:hello@fission.xyz">hello@fission.xyz</Anchor>.
          </Text>
        </ContentBox>
      )}
      {called == false && <LoginSignup starting_mode={starting_mode} />}
    </Group>
  )
})
