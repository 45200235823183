import { type ReactNode } from 'react'

import {
  Box,
  Button,
  Group,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { createFileRoute } from '@tanstack/react-router'

import { FollowUs } from '@repo/common/components/FollowUs'
import { LaunchApp } from '@repo/common/components/LaunchApp'
import { LinkButton } from '@repo/common/components/LinkButton'
import {
  type PartnerListName,
  Partners,
} from '@repo/common/components/Partners'
import { LabsDocsLink } from '@repo/common/helpers/cross_site_links'
import { useListFunds } from '@repo/common/queries/funds'

import { CompanyLogo } from '@/components/CompanyLogo'
import { ContentBox } from '@/components/ContentBox'
import { HomeFAQs } from '@/components/HomeFAQs'
import { InvestmentRow } from '@/components/InvestmentRow'
import { VCLogo } from '@/components/VCLogo'
import { useInvestorRankings } from '@/queries/company'

import logo_safari_video from '../-support/fission.mov'
// eslint-disable-next-line @cspell/spellchecker
import logo_video from '../-support/fission.webm'
import jon_url from '../-support/jon.jpg'
import investor_url from '../-support/lego.jpeg'
import quote_graphic from '../-support/quote_graphic.png'
import token_parts from '../-support/token_parts.webp'

import classes from '../-support/index.module.css'

// do to TanStack Router auto generate file routes
// prettier-ignore
export const Route = createFileRoute('/_webpage/')({
  component: IndexPage,
})

export const FAQs: { question: string; answer: ReactNode }[] = [
  {
    question: 'What is Fission’s mission?',
    answer:
      'Fission’s mission is to democratize access to VC investing by creating a global, liquid, on-chain marketplace. By leveraging DeFi infrastructure, users can buy, trade, and earn rewards on tokens backed by real venture equity.',
  },
  {
    question: 'Why should I use Fission?',
    answer:
      'Fission gives users an opportunity to buy tokens backed by top-tier VC funded private ventures (TECH token). Users can provide liquidity and lend/borrow these tokens, to earn both fees and governance token rewards (FISN token). For the first time on-chain, users can buy exciting private real world assets, with liquidity for trading and boosted returns.',
  },

  {
    question: 'How does Fission work?',
    answer:
      'Fission integrates a tokenization engine, with a DEX for trading, Vault for automated liquidity provisioning, and interest rate protocol for lending and borrowing. Users may mint or trade to purchase TECH, liquidity provide TECH & USDC to the DEX, and lend and borrow TECH against USDC collateral.',
  },
  {
    question: 'Is Fission trustworthy?',
    answer:
      'Fission is a venture-backed compliant protocol with legal infrastructure based in Cayman. It integrates all the traditional service providers and processes of TradFi (director, operators, compliance, legal, accounting) with a DeFi native experience.',
  },
  {
    question: 'How do I get started with Fission?',
    answer: 'We are currently in testnet – users may request access',
  },
]

const Investors: { src: string }[] = [
  { src: investor_url },
  { src: investor_url },
  { src: investor_url },
  { src: investor_url },
  { src: investor_url },
]
const TopCompanies = [
  'Stripe',
  'SpaceX',
  'Discord',
  'Circle',
  'Figma',
  'OpenAI',
]

const omit_partners: PartnerListName[] = ['Tally']

// eslint-disable-next-line max-lines-per-function
function IndexPage() {
  const { data: funds } = useListFunds()
  const { data: top_vcs } = useInvestorRankings()

  return (
    <Stack gap="100">
      <Box mt="250" pos="relative">
        <Box className={classes.video}>
          <video autoPlay muted loop width="100%" height="100%">
            <source src={logo_safari_video} type='video/mp4; codecs="hvc1"' />
            <source src={logo_video} type="video/webm" />
          </video>
        </Box>
        <Stack maw="650">
          <Title order={1} fz="title_lg" lh="title_lg" fw="normal">
            Invest in private ventures.
            <br />
            Exit whenever you want.
          </Title>
          <Text opacity={0.8}>
            Gone are the traditional barriers to entry – seamlessly invest in
            private companies through the security and transparency of the
            blockchain. Liquidate anytime.
          </Text>
          <Stack gap="xs">
            <Text size="md" opacity={0.8}>
              Companies Including
            </Text>
            <Group gap="sm">
              {TopCompanies.map((name) => (
                <CompanyLogo
                  cms_name={name}
                  title={name}
                  key={name}
                  h="3rem"
                  variant="white"
                />
              ))}
            </Group>
          </Stack>
          <Group mt="lg">
            <LaunchApp no_login />
          </Group>
        </Stack>
      </Box>
      <SimpleGrid cols={{ base: 1, sm: 2 }}>
        <Stack justify="center">
          <Title order={1} fz="title_lg" lh="title_lg" fw="normal">
            The hottest ventures
            <br />
            all in one token.
          </Title>
        </Stack>
        <Image src={token_parts} alt="Token parts" />
      </SimpleGrid>
      <Stack gap="xs" w="100%">
        <Text size="md" opacity={0.8}>
          Our Partners
        </Text>
        <Partners maw="100%" omit={omit_partners} />
      </Stack>
      <Stack mt={{ base: '0', md: '50' }}>
        <Group justify="space-between" align="flex-end">
          <Stack>
            <Title order={1} fz="title_md" lh="title_md" fw="normal">
              Invest in the best ventures
            </Title>
            <Text opacity={0.8} size="xl" maw="500">
              Buy and sell at the speed of the blockchain
            </Text>
          </Stack>
          <Stack>
            <Text size="sm">
              <Text span opacity={0.8}>
                Powered by{' '}
              </Text>
              <Text span fw="bold">
                Fission™
              </Text>
            </Text>
          </Stack>
        </Group>
        <Stack>
          <ContentBox>
            <InvestmentRow symbol="TECH" />
          </ContentBox>
          <Box px="lg">
            <Text>Coming Soon</Text>
          </Box>
          <ContentBox opacity={0.7}>
            <InvestmentRow symbol="FIN" />
          </ContentBox>
          <ContentBox opacity={0.7}>
            <InvestmentRow symbol="IND" />
          </ContentBox>
          <Group justify="flex-start">
            <LinkButton to="/investments" variant="light">
              View All {funds?.length ?? '(counting...)'} Investments
            </LinkButton>
          </Group>
        </Stack>
      </Stack>
      <Stack gap="xl2">
        <Title order={1} fz="xl" lh="xl" ta="center" fw="normal">
          Portfolio companies backed by top VCs
        </Title>
        <Group className={classes.vc_group} justify="center">
          {(top_vcs ?? []).slice(0, 4).map((name) => (
            <VCLogo
              h="80"
              name={name}
              key={name}
              title={name}
              variant="white"
            />
          ))}
        </Group>
      </Stack>
      <Stack gap="xl2">
        <Title order={1} fz="title_md" lh="title_md" ta="center" fw="normal">
          Built from the ground up
        </Title>
        <SimpleGrid cols={{ base: 1, md: 2 }}>
          <ContentBox p="xl">
            <Stack gap="xxl">
              <Title order={2} fw="normal">
                Buy and sell freely
              </Title>
              <Text size="xl">
                Connecting you with private equity - no friction, no
                frustration, no hurdles.
              </Text>
            </Stack>
          </ContentBox>
          <ContentBox p="xl">
            <Stack gap="xxl">
              <Title order={2} fw="normal">
                Professional grade
              </Title>
              <Text size="xl">
                Professional level software designed for every investor.
              </Text>
            </Stack>
          </ContentBox>
          <ContentBox p="xl" pb="xl2">
            <Stack gap="xxl">
              <Title order={2} fw="normal">
                Handpicked
              </Title>
              <Text size="xl">
                Portfolio holdings managed by seasoned investors coming from top
                investment firms.
              </Text>
            </Stack>
          </ContentBox>
          <ContentBox p="xl" pb="xl2">
            <Stack gap="xxl">
              <Title order={2} fw="normal">
                Fully secure
              </Title>
              <Text size="xl">
                Trade with peace of mind - Fission’s platform is built on
                cutting-edge secure networks audited by top security firms.
              </Text>
            </Stack>
          </ContentBox>
        </SimpleGrid>
      </Stack>
      <ContentBox p={{ base: 'xl', md: 'xl2' }}>
        <Group display="flex" justify="space-between" align="stretch">
          <Stack justify="space-between">
            <Box></Box>
            <Text size="title_xs" maw="350">
              “Fission is the future of private equity investing.”
            </Text>
            <Group>
              <Image src={jon_url} h="60px" radius="md" />
              <Stack gap="0">
                <Text>Jon Shaffer</Text>
                <Text size="sm">Fission Co-Founder & CEO</Text>
              </Stack>
            </Group>
          </Stack>
          <Image
            src={quote_graphic}
            alt="building graphic"
            maw={{ base: '350', md: '500' }}
          />
        </Group>
      </ContentBox>

      <Box p={{ base: 'xl', md: 'xl2' }} pb="xl" w="100%">
        <SimpleGrid cols={{ base: 1, md: 2 }}>
          <Stack>
            <Title order={1} fz="title_md" lh="title_md" maw="350" fw="normal">
              Frequently asked questions
            </Title>
            <Group>
              <Button component="a" href={LabsDocsLink.href} variant="outline">
                Read more in Docs
              </Button>
            </Group>
          </Stack>
          <HomeFAQs list={FAQs} />
        </SimpleGrid>
      </Box>
      <Stack maw="720" align="center" m="0 auto">
        <Group gap="xs">
          {Investors.map(({ src }, index) => (
            <Image
              key={index}
              bg="blue"
              w="30"
              h="30"
              src={src}
              style={{ borderRadius: '50%', border: '1px solid black' }}
            />
          ))}
        </Group>
        <Title order={1} fz="title_lg" lh="title_lg" ta="center" fw="normal">
          Join other top private equity investors today
        </Title>
        <Stack>
          <Group gap="xxs">
            <Text>Follow on</Text>
            <FollowUs />
          </Group>
          <LaunchApp no_login />
        </Stack>
      </Stack>
    </Stack>
  )
}
