import { memo, type ReactNode, useEffect, useState } from 'react'
import { SocialShareAndFollow } from 'react-share-follow'
import 'react-share-follow/dist/SocialShareAndFollow.css'

import { Anchor, Button, Group, Stack, Text } from '@mantine/core'

import { Logo } from '../../../../apps/labs/src/components/Logo'
import { useGetFlags } from '../../hooks/flags'
import { useAuth, useLogout, useRefresh } from '../../queries/auth'
import { FollowUs } from '../FollowUs'
import { LinkButton } from '../LinkButton'

import classes from './RequireAccess.module.css'

export const TestNetLink = 'https://testnet.fission.xyz/investments'

const default_redirect = (path: string) => {
  window.location.replace(path)
}

export const RequireAccess = memo<{
  children: ReactNode
  redirect?: (path: string) => void
  onClose?: () => void
  // eslint-disable-next-line complexity, max-lines-per-function
}>(function RequireAccess({ children, onClose, redirect = default_redirect }) {
  const { data } = useAuth()
  const reset = useLogout()
  const { is_main_site } = useGetFlags()
  const { mutate: refresh, isSuccess } = useRefresh()
  const [has_check, set_has_check] = useState(false)

  useEffect(() => {
    if (!has_check && data != null && !data.is_member) {
      refresh()
      set_has_check(true)
    }
  }, [data, has_check, refresh])

  useEffect(() => {
    if (is_main_site && data?.is_member) {
      redirect(TestNetLink)
    }
  }, [data?.is_member, is_main_site, redirect])

  if (!is_main_site && data?.is_member) {
    return children
  }

  return (
    <Stack maw="600px" align="center" mx="auto">
      <Group justify="center">
        <Logo size={120} />
      </Group>

      <Text size="lg">Thanks for signing up!</Text>
      <Text>
        You will receive your invitation email once you are approved.
        {!isSuccess && ' - Checking...'}
      </Text>
      <Text ta="center">
        If you have any questions or issues please reach out to us at{' '}
        <Anchor href="mailto:hello@fission.xyz">hello@fission.xyz</Anchor>.
      </Text>
      <Stack className={classes.social} gap="md" align="center">
        <Group gap="xs">
          <Text size="sm">Follow Fission for the latest updates</Text>
          <FollowUs />
        </Group>
        <SocialShareAndFollow
          url="https://fission.xyz/"
          title="VC is coming to DeFi!"
          description="I just joined Fission's testnet waitlist - join the revolution"
          labelShare="Share"
          labelFollow="Follow Fission for the latest updates"
          iconColor="var(--mantine-color-primary-3)"
          buttonColor="transparent"
          showCategoryIcons={false}
          share={{
            copy: true,
            email: true,
            sms: true,
            facebook: true,
            linkedin: true,
            reddit: true,
            twitter: true,
          }}
        />
      </Stack>
      <Group mt="lg">
        <Button
          size="sm"
          variant="subtle"
          loading={reset.isPending}
          onClick={() => reset.mutate()}
        >
          Logout to switch user accounts
        </Button>
        <LinkButton size="md" variant="outline" to="/" onClick={onClose}>
          Return to Home
        </LinkButton>
      </Group>
    </Stack>
  )
})
