import { memo } from 'react'

import {
  Box,
  Burger,
  Group,
  AppShell as MantineShell,
  Text,
} from '@mantine/core'
import { useDisclosure, useWindowScroll } from '@mantine/hooks'
import { Outlet } from '@tanstack/react-router'

import { FollowUs } from '@repo/common/components/FollowUs'
import { LaunchApp } from '@repo/common/components/LaunchApp/LaunchApp'

import { type NavLinks, NavSideLinks } from '@/components/NavSideLinks'

import { Footer } from '../Footer'
import { Link } from '../Link'
import { Logo } from '../Logo'
import { WebBackground } from '../WebBackground'

import classes from './WebsiteShell.module.css'

const header_links: NavLinks = []
const side_links: NavLinks = []

// TODO: page animations: https://github.com/TanStack/router/discussions/823
export const WebsiteShell = memo(function WebsiteShell() {
  const [nav_open, { toggle, close }] = useDisclosure()
  const [scroll] = useWindowScroll()

  const overlay = scroll.y > 40

  return (
    <WebBackground className={classes.root}>
      <MantineShell
        header={{ height: 'calc(60px + 1rem)' }}
        withBorder={false}
        navbar={{
          width: 230,
          breakpoint: 'sm',
          collapsed: { mobile: !nav_open, desktop: true },
        }}
      >
        <MantineShell.Header
          className={classes.header}
          data-overlay={overlay}
          data-nav-open={nav_open}
        >
          <Group w="100%" align="flex-start" justify="space-between" gap="sm">
            <Group>
              <Burger
                aria-label="Toggle navigation"
                opened={nav_open}
                onClick={toggle}
                hiddenFrom="sm"
                size="sm"
                aria-expanded={nav_open}
              />
              <Logo size={32} as_link with_name />
            </Group>

            <Group gap="sm" visibleFrom="sm">
              {header_links.map((props) => (
                <Link {...props} key={props.label} className={classes.link} />
              ))}
            </Group>
            <Group justify="flex-end" visibleFrom="sm">
              <FollowUs />
              <LaunchApp />
            </Group>
          </Group>
        </MantineShell.Header>
        <MantineShell.Navbar className={classes.navbar} onClick={close}>
          <NavSideLinks links={side_links}>
            <Group justify="center" gap="xxs">
              <Text>Follow on</Text>
              <FollowUs />
            </Group>
            <LaunchApp />
          </NavSideLinks>
        </MantineShell.Navbar>
        <MantineShell.Main>
          <Box
            maw="var(--website-content-width)"
            pr="var(--app-body-margin)"
            pl={{ base: 'var(--app-body-margin)', sm: '1rem' }}
            m="0 auto"
          >
            <Outlet />
          </Box>
          <Footer />
        </MantineShell.Main>
      </MantineShell>
    </WebBackground>
  )
})
