import type { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { formatUnits, type Hex, hexToBigInt, isHex } from 'viem'

export function calc_tokens(
  shares: bigint | undefined | Hex,
  decimals: number,
): number {
  if (shares == null) return 0

  if (isHex(shares)) {
    shares = hexToBigInt(shares)
  }

  return Number(formatUnits(shares, decimals))
}

export function findCurrencyAmount({
  symbol,
  pair,
  not = false,
}: {
  symbol: string
  pair: [CurrencyAmount<Token>, CurrencyAmount<Token>]
  not?: boolean
}): CurrencyAmount<Token> {
  if (not) {
    return pair[0].currency.symbol === symbol ? pair[1] : pair[0]
  }
  return pair[0].currency.symbol === symbol ? pair[0] : pair[1]
}
