import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import {
  skipToken,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { MaxAllowanceTransferAmount } from '@uniswap/permit2-sdk'
import { erc20Abi } from 'abitype/abis'
import { usePostHog } from 'posthog-js/react'
import {
  useAccount,
  usePublicClient,
  useSwitchChain,
  useWriteContract,
} from 'wagmi'

import { active_chain } from '@repo/common/blockchain/config'

import { useGetContractAddress } from '../crypto_contracts'
import { type TokenSymbol, useTokenInfo } from '../products'

export const keys = createQueryKeyStore({
  blockchain: {
    permit2_enabled: (args: {
      symbol: string
      wallet_address: CryptoAddress
    }) => [args],
  },
})

export function useCheckPermit2({ symbol }: { symbol: TokenSymbol }) {
  const token_address = useTokenInfo({ symbol }).data?.token_address
  const { address: wallet_address } = useAccount()
  const client = usePublicClient({ chainId: active_chain.id })
  const permit2_address = useGetContractAddress('PERMIT2')

  const enabled =
    token_address != null &&
    wallet_address != null &&
    client != null &&
    permit2_address != null

  return useQuery({
    staleTime: Infinity,
    queryKey: keys.blockchain.permit2_enabled({
      symbol,
      wallet_address: wallet_address!,
    }).queryKey,
    queryFn: !enabled
      ? skipToken
      : async () => {
          const token_allowance = await client.readContract({
            abi: erc20Abi,
            address: token_address,
            functionName: 'allowance',
            args: [wallet_address, permit2_address],
          })

          return {
            token_allowance,
            permit_enabled:
              token_allowance >= MaxAllowanceTransferAmount.toBigInt() / 2n,
          }
        },
  })
}

export function useSetupPermit2({ symbol }: { symbol: TokenSymbol }) {
  const token_address = useTokenInfo({ symbol }).data?.token_address
  const permit2_address = useGetContractAddress('PERMIT2')
  const contract = useWriteContract()
  const { switchChainAsync } = useSwitchChain()
  const queryClient = useQueryClient()
  const client = usePublicClient({ chainId: active_chain.id })
  const { address: wallet_address } = useAccount()
  const posthog = usePostHog()

  const isReady =
    permit2_address != null &&
    token_address != null &&
    wallet_address != null &&
    client != null

  const result = useMutation({
    mutationFn: async () => {
      if (!isReady) {
        console.warn('called before enabled')
        return
      }

      // ensure network is correct
      await switchChainAsync({ chainId: active_chain.id })

      const tx = await contract.writeContractAsync({
        abi: erc20Abi,
        address: token_address,
        functionName: 'approve',
        chainId: active_chain.id,
        args: [permit2_address, MaxAllowanceTransferAmount.toBigInt()],
      })
      await client.waitForTransactionReceipt({ hash: tx })
      posthog.capture('permit2 enabled', { symbol })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: keys.blockchain.permit2_enabled({
          symbol,
          wallet_address: wallet_address!,
        }).queryKey,
      })
    },
  })

  return {
    ...result,
    isReady,
  }
}
