import { createFileRoute } from '@tanstack/react-router'

import { LoginSignupPage } from '@/components/LoginSignupPage'

// do to TanStack Router auto generate file routes
// prettier-ignore
export const Route = createFileRoute('/_appish/login/$code')({
  component: LoginPage,
})

function LoginPage() {
  const { code } = Route.useParams()
  return <LoginSignupPage starting_mode="login" membership_code={code} />
}
