export type BeaconArgs =
  | [
      'identify',
      args: { name: string; email: string; user_id: string; signature: string },
    ]
  | ['event', args: { type: 'page-viewed'; url: string; title: string }]
  | ['suggest']
  | ['open']
  | ['navigate', route: string]

declare global {
  interface Window {
    Beacon: (...args: BeaconArgs) => void
  }
}

const beacon_wrapper = (...args: BeaconArgs) => window.Beacon(...args)

export function useBeacon() {
  return beacon_wrapper
}
