import { useMutation } from '@tanstack/react-query'

import { graphql_call } from '@repo/common/queries/helpers/server_query'

import { useRefresh } from '../auth'

import { update_membership } from './membership_graphql'

export function useUpdateMembership() {
  const { mutateAsync: refresh } = useRefresh()
  return useMutation({
    mutationFn: async ({ code }: { code: string }) => {
      const result = await graphql_call({
        query: update_membership,
        variables: {
          input: {
            code,
          },
        },
      })
      await refresh()
      return result
    },
  })
}
