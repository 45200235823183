import { useCallback } from 'react'

import { AllowanceTransfer } from '@uniswap/permit2-sdk'
import dayjs from 'dayjs'
import { usePostHog } from 'posthog-js/react'
import { useAccount, usePublicClient, useWalletClient } from 'wagmi'

import { active_chain } from '@repo/common/blockchain/config'

import { useGetContractAddress } from '../crypto_contracts'

import { permit2Abi } from './abis/permit2'

type PermitDetails = {
  token: CryptoAddress
  amount: bigint
  expiration: number
  nonce: number
}
export type PermitSingleCorrect = {
  details: PermitDetails
  spender: CryptoAddress
  sigDeadline: bigint
}

export type PermitDoubleCorrect = {
  details: [PermitDetails, PermitDetails]
  spender: CryptoAddress
  sigDeadline: bigint
}

type TokenInput = {
  address: CryptoAddress
  symbol: string
  amount: bigint
}
// eslint-disable-next-line max-lines-per-function
export function useCreateAndSignPermit2() {
  const posthog = usePostHog()
  const { data: wallet_client } = useWalletClient({ chainId: active_chain.id })
  const client = usePublicClient({ chainId: active_chain.id })
  const { address: wallet_address } = useAccount()
  const permit2_address = useGetContractAddress('PERMIT2')

  const isReady =
    client != null &&
    wallet_address != null &&
    permit2_address != null &&
    wallet_client != null

  const createSingleAsync = useCallback(
    async ({
      token_address,
      token_symbol,
      destination_address,
      token_amount,
    }: {
      token_address: CryptoAddress
      token_symbol: string
      destination_address: CryptoAddress
      token_amount: bigint
    }) => {
      if (!isReady) {
        throw new Error('swap called before ready')
      }

      const [, , permit2_nonce] = await client.readContract({
        abi: permit2Abi,
        address: permit2_address,
        functionName: 'allowance',
        args: [wallet_address, token_address, destination_address],
      })

      const permitSingle: PermitSingleCorrect = {
        details: {
          token: token_address,
          amount: token_amount,
          expiration: dayjs().add(30, 'minutes').unix(),
          nonce: permit2_nonce,
        },
        spender: destination_address,
        sigDeadline: BigInt(dayjs().add(30, 'minutes').unix()),
      }

      const { domain, types, values } = AllowanceTransfer.getPermitData(
        permitSingle,
        permit2_address,
        active_chain.id,
      )
      const signature = await wallet_client.signTypedData({
        account: wallet_address,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        message: values as Record<string, any>,
        domain: {
          name: domain.name,
          chainId: active_chain.id,
          verifyingContract: domain.verifyingContract as CryptoAddress,
          salt: undefined,
        },
        primaryType: 'PermitSingle',
        types,
      })
      posthog.capture('permit2 signed', { symbol: token_symbol })

      return { signature, permitSingle }
    },
    [client, isReady, permit2_address, posthog, wallet_address, wallet_client],
  )

  const createDoubleAsync = useCallback(
    async ({
      tokens,
      destination_address,
    }: {
      tokens: [TokenInput, TokenInput]
      destination_address: CryptoAddress
    }) => {
      if (!isReady) {
        throw new Error('swap called before ready')
      }

      const results = await client.multicall({
        contracts: [
          {
            abi: permit2Abi,
            address: permit2_address,
            functionName: 'allowance',
            args: [wallet_address, tokens[0].address, destination_address],
          },
          {
            abi: permit2Abi,
            address: permit2_address,
            functionName: 'allowance',
            args: [wallet_address, tokens[1].address, destination_address],
          },
        ],
      })

      const permitDouble: PermitDoubleCorrect = {
        details: [
          {
            token: tokens[0].address,
            amount: tokens[0].amount,
            expiration: dayjs().add(30, 'minutes').unix(),
            nonce: results[0].result![2],
          },
          {
            token: tokens[1].address,
            amount: tokens[1].amount,
            expiration: dayjs().add(30, 'minutes').unix(),
            nonce: results[1].result![2],
          },
        ],
        spender: destination_address,
        sigDeadline: BigInt(dayjs().add(30, 'minutes').unix()),
      }

      const { domain, types, values } = AllowanceTransfer.getPermitData(
        permitDouble,
        permit2_address,
        active_chain.id,
      )
      const signature = await wallet_client.signTypedData({
        account: wallet_address,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        message: values as Record<string, any>,
        domain: {
          name: domain.name,
          chainId: active_chain.id,
          verifyingContract: domain.verifyingContract as CryptoAddress,
          salt: undefined,
        },
        primaryType: 'PermitBatch',
        types,
      })
      posthog.capture('permit2 batch signed', { symbol: tokens[1].symbol })

      return { signature, permitDouble }
    },
    [client, isReady, permit2_address, posthog, wallet_address, wallet_client],
  )

  return {
    isReady,
    createSingleAsync,
    createDoubleAsync,
  }
}
