import Userfront from '@userfront/core'
import { initGraphQLTada } from 'gql.tada'
import { GraphQLClient } from 'graphql-request'

import type { introspection } from '../server-graphql-env'

import { create_graphql_call } from './utilities.ts'

export const graphql = initGraphQLTada<{
  introspection: introspection
  scalars: {
    ISO8601Date: ISO8601Date
    ISO8601DateTime: ISO8601DateTime
    ID: RubyID
    CryptoAddress: CryptoAddress
  }
}>()

export const server_client = new GraphQLClient(
  `${import.meta.env.VITE_API_URL}/graphql`,
  {
    headers: () => ({
      Authorization: Userfront?.tokens?.accessToken
        ? `Bearer ${Userfront.tokens.accessToken}`
        : '',
    }),
  },
)

export const graphql_call = create_graphql_call(server_client)
