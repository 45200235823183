import { useMemo } from 'react'

import { createQueryKeyStore } from '@lukemorales/query-key-factory'

import { useGetTokenPrice } from '../fission_dex'
import { useFundLatestPriceData } from '../funds'

export const keys = createQueryKeyStore({
  gov: {
    mint_reward: (args: { fund_id: string; mint_value: number }) => [args],
  },
})

function mint_rewards({
  mint_value,
  tvl,
}: {
  mint_value: number
  tvl: number
}): number {
  // (-0.5 * 10**8 * (Math::E**(-2.23 * 10**-8 * (tvl + amount)) - Math::E**(-2.23 * 10**-8 * tvl))).floor

  return Math.floor(
    -0.5 *
      Math.pow(10, 8) *
      (Math.exp(-2.23 * Math.pow(10, -8) * (tvl + mint_value)) -
        Math.exp(-2.23 * Math.pow(10, -8) * tvl)),
  )
}

export function useGetGovMintRewards({
  fund_id,
  mint_value,
}: {
  fund_id: undefined | string
  mint_value: number
}) {
  const { data, isSuccess } = useFundLatestPriceData({ id: fund_id })
  const { data: fisn_price } = useGetTokenPrice({ symbol: 'FISN' })

  const return_data = useMemo(() => {
    let tokens: number

    if (data == null && isSuccess && fisn_price != null) {
      tokens = mint_rewards({
        mint_value,
        tvl: 0,
      })
    } else if (data == null || fisn_price == null) {
      return { tokens: 0, percentage: 0 }
    } else {
      tokens = mint_rewards({
        mint_value,
        tvl: Number(data.tvl_usdc),
      })
    }

    const token_value = tokens * fisn_price

    const percentage = (token_value / mint_value) * 100

    return { tokens, percentage }
  }, [data, fisn_price, isSuccess, mint_value])

  return { data: return_data }
}
