import { graphql } from '@repo/common/queries/helpers/uniswap_query'

export const query_get_liquidity = graphql(`
  query get_liquidity($id: ID!, $skip: Int!) {
    pool(id: $id) {
      feeTier
      liquidity
      sqrtPrice
      tick
      ticks(
        first: 1000
        skip: $skip
        orderBy: tickIdx
        orderDirection: asc
        where: { liquidityNet_not: 0 }
      ) {
        tickIdx
        liquidityNet
        liquidityGross
      }
      token0 {
        id
        symbol
        name
        decimals
      }
      token1 {
        id
        symbol
        name
        decimals
      }
    }
  }
`)

export const query_get_pool_stats = graphql(`
  query query_get_pool_stats($id: ID!) {
    pool(id: $id) {
      volumeToken0
      volumeToken1
      totalValueLockedToken0
      totalValueLockedToken1
      token0 {
        symbol
      }
      token1 {
        symbol
      }
      history: poolDayData(first: 1, orderBy: date, orderDirection: desc) {
        volumeToken0
        volumeToken1
        date
      }
      first: poolDayData(first: 1, orderBy: date, orderDirection: asc) {
        date
      }
    }
  }
`)

export const query_get_hourly_price = graphql(`
  query get_hourly_price($id: ID!) {
    pool(id: $id) {
      token0 {
        name
      }
      token1 {
        name
      }
      history: poolHourData(
        first: 24
        orderBy: periodStartUnix
        orderDirection: desc
        where: { liquidity_gt: 0 }
      ) {
        date: periodStartUnix
        token0Price
        token1Price
      }
    }
  }
`)

export const query_get_daily_price = graphql(`
  query get_daily_price($id: ID!, $firstTime: Int!) {
    pool(id: $id) {
      token0 {
        name
      }
      token1 {
        name
      }
      history: poolDayData(
        first: 365
        orderBy: date
        orderDirection: desc
        where: { and: [{ date_gte: $firstTime }, { liquidity_gt: 0 }] }
      ) {
        date
        token0Price
        token1Price
      }
    }
  }
`)
